import { DevLog, IS_DEV } from "@Platon/const"
import { Toasts } from "@Platon/core/runScope"

export default {
	data() {
		return {
			/**
			 * @type Map<string, function[]>
			 */
			changeListeners: new Map()
		}
	},

	methods: {
		/**
		 * @param {string} js
		 */
		handleFormJs(js) {
			try {
				this.injectScopeJs(js, this.thisForm, {
					$form: this.thisForm,
					form: this.thisForm,
					$w: this.$w,
					$watchAll: this.$watchAll,
					$data: this.dataHolder,
					$setVal: this.$setVal,
					$setVals: this.$setVals,
					$setAttr: this.$setElementAttr,
					$setAttrs: this.$setElementAttrs,
					$option: this.$getSelectOption,
					$m: window.moment,
					Toasts
				})
			} catch (e) {
				console.error(e)
			}
		},

		/**
		 * @param {string|string[]} field
		 * @param {function} callback
		 * @param {WatchOptions} watchOptions
		 */
		$w(field, callback, watchOptions) {
			if (callback) {
				if (field === null) {
					this.$watch(`dataHolder`, callback, watchOptions)
				} else if (Array.isArray(field)) {
					for (let f of field) {
						this.$watch(`dataHolder.${f}`, callback, watchOptions)
					}
				} else {
					this.$watch(`dataHolder.${field}`, callback, watchOptions)
				}
			} else {
				if (IS_DEV) console.warn(`Callback not defined for field: ${field}`)
			}
		},

		/**
		 * @param {string|string[]} fields
		 * @param {function} callback
		 * @param watchOptions
		 */
		$watchAll(fields, callback, watchOptions) {
			this.$watch(
				() => {
					return fields.map((f) => this.dataHolder[f])
				},
				callback,
				watchOptions
			)
		},

		/**
		 * @param {string} field
		 * @param {any} value
		 * @param notify
		 */
		$setVal(field, value, notify = true) {
			this.formSetData(field, value, notify)
		},

		/**
		 * @param {Object} keyVals
		 * @param notify
		 */
		$setVals(keyVals, notify = true) {
			let entries = Object.entries(keyVals)

			for (let [key, val] of entries) {
				this.$setVal(key, val, notify)
			}
		},

		/**
		 * @param {string} elementKey
		 * @param {string} attr
		 * @param {any} value
		 */
		$setElementAttr(elementKey, attr, value) {
			DevLog("SetAttr", attr, value)

			if (this.formElementsWithKey[elementKey]) {
				this.$set(this.formElementsWithKey[elementKey], attr, value)
			} else {
				if (IS_DEV) console.warn(`Element does not exists ${elementKey}`)
			}
		},

		/**
		 * @param {string} elementKey
		 * @param {Object} attrList
		 */
		$setElementAttrs(elementKey, attrList) {
			for (let [key, value] of Object.entries(attrList)) {
				this.$setElementAttr(elementKey, key, value)
			}
		},

		/**
		 * Shortcut for getting item of form select element
		 * @param {string} dataField
		 */
		$getSelectOption(dataField) {
			let el = this.elements.get(dataField)

			if (el && el.$refs.element && el.$refs.element.$options.name === "SelectControl") {
				return el.$refs.element.selectedOption
			}

			return null
		},

		/**
		 * @param {string|string[]} key
		 * @param handler
		 */
		$onChange(key, handler) {
			const register = (key) => {
				if (!this.$data.changeListeners.has(key)) {
					this.$data.changeListeners.set(key, [])
				}

				this.$data.changeListeners.get(key).push(handler)
			}

			if (Array.isArray(key)) {
				for (let keyElement of key) {
					register(keyElement)
				}
			} else {
				register(key)
			}
		},

		/**
		 * @param {string} key
		 * @param value
		 */
		onFormDataSet(key, value) {
			if (this.$data.changeListeners.has(key)) {
				this.$data.changeListeners.get(key).forEach((fn) => fn(value, key))
			}

			if (this.$data.changeListeners.has("*")) {
				this.$data.changeListeners.get("*").forEach((fn) => fn(value, key))
			}
		}
	}
}
